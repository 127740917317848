import {
  TideItemForDestinations,
  TideItemForHotelConnection,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForRoundtripConnection,
  TideItemForSearchPage,
  TideItemForTheme,
  TideItemForThemes,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import { compact, first, isEmpty, min } from "lodash";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import React from "react";
import ThemeGrid from "../components/theme-grid";
import { generatePath, getChildNavigationLinks } from "../utils";
import { graphql } from "gatsby";
import StickyBar from "../components/sticky-bar";
import Link from "../components/link";
import Icon from "../components/icon";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface DestinationsPageTemplateProps {
  data: DestinationsPageData;
  pageContext: {
    alternateRoutes: AlternateRoute[];
  };
}

const ThemesPageTemplate: React.FC<DestinationsPageTemplateProps> = ({ data, pageContext }) => {
  const notificationBar = first(data.notifications?.nodes);
  const { t } = useI18next();

  const title = data.themesPage.content?.general?.title ?? undefined;
  const introduction = data.themesPage.content?.general?.introduction ?? undefined;
  const themes = data.themesPage.childItems?.filter(
    (childItem) => childItem?.templateName === "Theme"
  ) as TideItemForTheme[];

  const minPrices = [...data.roundtripsWithThemes.nodes, ...data.hotelsWithThemes.nodes].reduce<{
    [key: string]: number | undefined;
  }>((prices, product) => {
    ((product.content?.general?.themesIds as string[]) || []).forEach((id) => {
      prices[id] = min(
        compact([
          prices[id] ?? undefined,
          product.content?.general?.estimatedPrice ?? undefined,
          product.content?.general?.estimatedPromoPrice ?? undefined,
        ])
      );
    });
    return prices;
  }, {});

  return (
    <Layout>
      <Seo
        title={data.themesPage.content?.general?.title ?? ""}
        seoTitle={data.themesPage.content?.seo?.seoTitle ?? undefined}
        seoDescription={data.themesPage.content?.seo?.seoDescription ?? undefined}
        seoKeywords={data.themesPage.content?.seo?.seoKeywords ?? undefined}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext?.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar
        navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
        actionsComponent={
          data.searchPage && (
            <Link
              href={`/${data.searchPage.content?.general?.slug}`}
              internal
              title={t("SEARCH_AND_BOOK")}
              className="cta cta--icon"
            >
              <Icon name="ui-search" />
              <Trans>SEARCH_AND_BOOK</Trans>
            </Link>
          )
        }
      />
      <Hero
        type="compact"
        title={title}
        imageSrc={data.themesPage?.content?.general?.headerImage?.url ?? undefined}
      />
      <Breadcrumb
        items={compact([
          { id: "home", title: "Home", url: "/" },
          {
            id: "themes",
            title: data.themesPage.content?.general?.title ?? "",
            url: generatePath(data.themesPage),
          },
        ])}
      />
      <ThemeGrid
        searchPath={data.searchPage.content?.general?.slug ?? "zoeken"}
        title={title}
        introduction={introduction}
        themes={themes}
        hotelsWithThemes={data.hotelsWithThemes.nodes}
        roundtripsWithThemes={data.roundtripsWithThemes.nodes}
        minPrices={minPrices}
        themesPagePath={generatePath(data.themesPage)}
      />
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
    </Layout>
  );
};

interface DestinationsPageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  themesPage: TideItemForThemes;
  hotelsWithThemes: TideItemForHotelConnection;
  roundtripsWithThemes: TideItemForRoundtripConnection;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query ThemesPageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    themesPage: tideItemForThemes(id: { eq: $id }) {
      name
      content {
        general {
          title
          introduction
          headerImage {
            url
          }
          slug
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      childItems {
        templateName
        ... on TideItemForTheme {
          id
          itemId
          name
          content {
            general {
              title
              intro
              thumbnailPicture {
                url
              }
              slug
            }
          }
        }
      }
    }
    hotelsWithThemes: allTideItemForHotel(
      filter: { content: { general: { themesIds: { ne: "[]" } } }, language: { eq: $language } }
    ) {
      nodes {
        id
        content {
          general {
            themesIds
            estimatedPrice
            estimatedPromoPrice
          }
        }
      }
    }
    roundtripsWithThemes: allTideItemForRoundtrip(
      filter: { content: { general: { themesIds: { ne: "[]" } } }, language: { eq: $language } }
    ) {
      nodes {
        id
        content {
          general {
            themesIds
            estimatedPrice
            estimatedPromoPrice
          }
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default ThemesPageTemplate;
