import { compact } from "lodash";
import React, { useEffect, useState } from "react";
import { TideItemForHotel, TideItemForRoundtrip, TideItemForTheme } from "../types";
import { generatePath, setImageParameters } from "../utils";
import Icon from "./icon";
import Link from "./link";
import { fetchLookupData } from "./lookup-context/mapping";
import { LookupData } from "./lookup-context/types";
import Pricing from "./pricing";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ThemeGridProps {
  searchPath: string;
  title?: string;
  introduction?: string;
  themes?: TideItemForTheme[];
  hotelsWithThemes?: TideItemForHotel[];
  roundtripsWithThemes?: TideItemForRoundtrip[];
  minPrices: { [key: string]: number | undefined };
  themesPagePath?: string;
  showPrice?: boolean;
}

const ThemeGrid: React.FC<ThemeGridProps> = ({
  searchPath,
  title,
  introduction,
  themes,
  hotelsWithThemes,
  roundtripsWithThemes,
  minPrices,
  themesPagePath,
  showPrice,
}) => {
  const { t, language } = useI18next();
  const countThemes = (items: (TideItemForHotel | TideItemForRoundtrip)[]) =>
    items.reduce<{ [key: string]: number }>((counts, product) => {
      ((product.content?.general?.themesIds as string[]) || []).forEach((id) => {
        counts[id] = (counts[id] ?? 0) + 1;
      });
      return counts;
    }, {});

  const hotelCounts = countThemes(compact(hotelsWithThemes));
  const roundtripCounts = countThemes(compact(roundtripsWithThemes));

  const [lookupData, setLookupData] = useState<LookupData[]>([]);

  const asyncFetchLookupData = async () => {
    const data = await fetchLookupData(language);
    setLookupData(data);
  };

  useEffect(() => {
    asyncFetchLookupData();
  }, []);

  const buildHotelThemeUrl = (themeId: string) => {
    if (hotelCounts[themeId] === 1) {
      const hotelId = hotelsWithThemes?.find((hotel) =>
        hotel.content?.general?.themesIds?.includes(themeId)
      )?.id;
      return lookupData.find((lookup) => lookup.id === hotelId)?.url;
    } else {
      return `/${searchPath}?theme=${themeId}`;
    }
  };

  const buildRoundtripThemeUrl = (themeId: string) => {
    if (roundtripCounts[themeId] === 1) {
      const roundtripId = roundtripsWithThemes?.find((roundtrip) =>
        roundtrip.content?.general?.themesIds?.includes(themeId)
      )?.id;
      return lookupData.find((lookup) => lookup.id === roundtripId)?.url;
    } else {
      return `/${searchPath}?theme=${themeId}`;
    }
  };

  return (
    <section className="section">
      <div className="productcard-grid productcard-grid--4">
        <div className="productcard-grid__copy">
          <section className="section">
            <div className="single-column">
              <div className="single-column__container">
                <div className="single-column__title">
                  <h2>{title}</h2>
                </div>
                <div
                  className="single-column__column"
                  dangerouslySetInnerHTML={{ __html: introduction ?? "" }}
                ></div>
              </div>
            </div>
          </section>
        </div>
        <div className="productcard-grid__items">
          {themes
            ?.filter((theme) => (hotelCounts[theme.id] ?? 0) + (roundtripCounts[theme.id] ?? 0) > 0)
            .map((theme) => {
              const themePath = generatePath(theme, themesPagePath);
              const roundtripsForTheme = roundtripCounts[theme.id] ?? 0;
              const hotelsForTheme = hotelCounts[theme.id] ?? 0;

              return (
                (roundtripsForTheme > 0 || hotelsForTheme > 0) && (
                  <div className="productcard productcard--descriptive" key={theme.id}>
                    <div className="productcard__header">
                      <div className="image image--golden-ratio">
                        <Link internal href={themePath} className="image__link">
                          <img
                            src={
                              theme.content?.general?.thumbnailPicture?.url
                                ? setImageParameters(theme.content.general.thumbnailPicture.url, {
                                    width: 785,
                                    height: 550,
                                    mode: "crop",
                                  })
                                : undefined
                            }
                            alt={theme.content?.general?.title ?? undefined}
                            className="image__content"
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="productcard__body">
                      <h4 className="productcard__heading">
                        <Link internal href={themePath} className="productcard__heading-link">
                          {theme.content?.general?.title}
                        </Link>
                      </h4>

                      <div className="link-items">
                        {roundtripsForTheme > 0 && (
                          <div className="link-item">
                            <Link
                              internal={true}
                              href={buildRoundtripThemeUrl(theme.id)}
                              className="link"
                            >
                              <Icon name="ui-route" />
                              {`${roundtripsForTheme} ${
                                roundtripsForTheme === 1 ? t("ROUNDTRIP") : t("ROUNDTRIPS")
                              }`}
                            </Link>
                          </div>
                        )}

                        {hotelsForTheme > 0 && (
                          <div className="link-item">
                            <Link internal href={buildHotelThemeUrl(theme.id)} className="link">
                              <Icon name="ui-bed" />
                              {`${hotelsForTheme ?? 0} ${
                                hotelsForTheme === 1 ? t("HOTEL") : t("HOTELS")
                              }`}
                            </Link>
                          </div>
                        )}
                      </div>

                      <div
                        className="productcard__description"
                        dangerouslySetInnerHTML={{ __html: theme.content?.general?.intro ?? "" }}
                      ></div>

                      <div className="productcard__summary">
                        {showPrice && minPrices[theme.id] && (
                          <Pricing defaultPrice={minPrices[theme.id] as number} />
                        )}
                        <Link internal href={themePath} className="cta">
                          {t("VIEW_THIS_THEME")}
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </section>
  );
};
export default ThemeGrid;
